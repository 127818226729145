"use client"
import React from "react";
import Link from 'next/link';
import { Chip } from "@nextui-org/chip";
import {Image} from "@nextui-org/image";
import { FaPlay } from "react-icons/fa6";
import { useBreakpoint } from "@/src/hooks/useBreakpoint";
import { FaRegEye } from "react-icons/fa";

export default function MovieMediumCard({movie,onClose}) {
    const { isDownMd } = useBreakpoint('md');
    return (
        <>
        <Link onClick={() => onClose && onClose(false)} prefetch={false} href={`/xem-phim/${movie.slug}`} >
            <div className="flex shadow-small rounded-xl group relative overflow-hidden bg-black m-0 pt-[50%]">
                <div className="self-center absolute top-[0%] w-full">
                    <div className="overflow-hidden flex justify-center">
                        <Image removeWrapper isBlurred alt={movie.name} isZoomed={isDownMd ? false : true} src={movie.thumb_image} className="md:group-hover:blur-sm transition aspect-[unset] object-top object-cover w-full"/>
                    </div>
                </div>
                <div className="absolute bottom-1 right-1 z-10 flex gap-1">                
                    {
                        movie.language && movie.language !== 'none' &&
                        <Chip className="dark:text-white text-black" size="sm" radius="md" color="danger">
                            {movie.language && movie.language}
                        </Chip>
                    }               
                    {
                        movie.view_total > 0 &&
                        <Chip className="dark:text-white text-black" size="sm" radius="md" color="danger">
                            <span className="flex items-center gap-1">{movie.view_total} <FaRegEye/></span>
                        </Chip>
                    }
                </div>
                <div className="hidden md:block opacity-0 transition-all duration-[0.65s] ease-[ease-in-out] group-hover:opacity-100 group-hover:scale-75  absolute top-[calc(50%_-_25px)] left-[calc(50%_-_25px)] z-10">
                    <FaPlay className="text-background" size={50}/>
                </div>
            </div>
            <div className="pt-2 w-full">
                <h2 className="text-sm font-bold overflow-hidden whitespace-nowrap max-w-[210px] text-ellipsis">
                    {movie.name}
                </h2>
                <h3 className="text-xs mt-2 text-default-500 overflow-hidden whitespace-nowrap max-w-[200px] text-ellipsis">{movie.origin_name}</h3>
            </div>
        </Link>
        </>
    );
}
