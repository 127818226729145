import {nextui} from "@nextui-org/react";

/** @type {import('tailwindcss').Config} */
const config = {
  content: [
    "./src/**/*.{js,ts,jsx,tsx}",
    './node_modules/@nextui-org/theme/dist/components/(button|input|card|image|tooltip|link|listbox|spinner|modal|tabs|scroll-shadow|progress|pagination|chip|skeleton|avatar|dropdown|select|checkbox|navbar|badge|divider|user|accordion|listbox|switch|popover|radio).js'
  ],
  variants: {
    extend: {
      fontWeight: ["responsive", "hover", "focus"],
      opacity: ["hover"],
      borderColor: ["hover", "focus"],
      margin: ["first", "last"],
      backgroundColor: ["odd", "even"],
      scale: ["hover", "active", "group-hover"],
    },
  },
  darkMode: ['selector', '[data-theme="dark"]'],
  theme: {
    screens:{
      'sm': '640px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1280px',
      '2xl': '1536px',
      '3xl': '1920px',
    },
    container: {
      center: true,
      padding: {
        DEFAULT: '1rem',
        md: '1.5rem',
        lg: '2rem'
      }
    }
  },
  plugins: [
    nextui({
    layout: {
      spacingUnit: 4, // in px
      disabledOpacity: ".5", // this value is applied as opacity-[value] when the component is disabled
      dividerWeight: "1px", // h-divider the default height applied to the divider component
      fontSize: {
        tiny: "0.75rem", // text-tiny
        small: "0.875rem", // text-small
        medium: "1rem", // text-medium
        large: "1.125rem", // text-large
      },
      lineHeight: {
        tiny: "1rem", // text-tiny
        small: "1.25rem", // text-small
        medium: "1.5rem", // text-medium
        large: "1.75rem", // text-large
      },
      radius: {
        small: "8px", // rounded-small
        medium: "12px", // rounded-medium
        large: "14px", // rounded-large
      },
      borderWidth: {
        small: "1px", // border-small
        medium: "2px", // border-medium (default)
        large: "3px", // border-large
      },
    },
    themes: {
      dark: {
        colors: {
          background: '#0b152b',
          foreground: '#fff',
          danger:{
            DEFAULT:'#9E0A3D',
            foreground:'#ffffff',
          },
          primary: {
            DEFAULT: '#9ca3af',
            foreground:'#ffffff',
          },
          focus: 'rgb(2 132 199)'
        },
      },
    }
  })]
}

export default config;