import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/@nextui-org/card/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/@nextui-org/skeleton/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/phimheofuk.com/src/components/Card/MovieCard.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/src/components/Card/MovieMediumCard.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/phimheofuk.com/src/components/SideBar/TabSideBar.jsx");
